@media print {
  #repoSubmit {
    display: none;
  }
}
.btn-pad-left {
  margin-left:10px;
}
.report-opt-div {
  padding-top:5px;
  padding-bottom:5px;
  border-bottom:#ddd 1px solid;
}
.repo-submit-btn {
  margin-top: 5px;
}
.app-btn {
  border-width:0;
  color:#0064b7;
  background-color: rgba(255,255,255,0);
  padding:0
}
#bday-checkbox {
  position: relative;
  top:-2px;
  color:green
}
#bday-checkbox span {margin-right:5px;text-transform: uppercase;font-size:12px;font-weight: bold}
#bday-checkbox input {margin-right: 10px}
#bday-checkbox i {margin-right:5px}
.profile-btns {margin: 5px}
.profile-btns i {color:green}
.profile-btns small {color:gray;margin-left:2px;font-size:10px;display:none}
.app-placeholder {margin-left: 15px;float:left}
.app-checkbox strong {margin: 0px 5px 0px 5px;}
.app-checkbox span {font-size: 13px;color:grey}
.app-img-responsive {width: 125px;height: 125px;}
.app-logo-container {width:20%;height:auto;float:left;margin-right:10px}
.float-right {float:left}
.app-btn-w { width:80px;margin-right:10px}
.app-td-heading { border-top:0px;background-color: #efefef }
.app-vh { height:100vh; }
.app-spliter { margin: 0px 5px 0px 5px }
.app-spinner-color { color: #84e3ff }
.app-pos-navbar {
    background: rgba(255,255,255, 0.5);
    margin-bottom: 0px;
    border-radius: 0px;
}
.app-modal-pad {
    padding: 20px;
}
.app-hr {
    border-top: #84e3ff 1px solid;
    height: 1px;
    margin: 8px 0px 10px 0px;
}
.uppercase-label {
    text-transform: uppercase;
}
.currency-label {
	margin-top: 1
}
.app-hr-thin-blue {
    border-top: #84e3ff 1px solid;
    height: 1px;
    margin: 5px 0px 7px 0px;
}

.app-hr-thin {
    border-top: #000 1px solid;
    height: 1px;
    margin: 5px 0px 7px 0px;
}
.app-gt-input {
    font-weight: bold;
    color: green
}
.fa-print {
    margin-right: 5px;
}
.scrollable {
    overflow-y: scroll;
    height: calc(36.5vh);
    border-bottom: #efefef 0px dashed;
}

.btn-toggle-nric {margin-right: 10px; height: 38px; color: #ccc}
.select-container {width: 340px}
.select-container-outlet {width: 200px}
.sale-sum-tbl { width: 100%; border: #ccc 0px solid}
.datepicker-select-repo {
    border-radius: 5px;
    border-collapse: collapse;
    border: #ccc 1px solid;
    padding: 8px;
    font-size: 16px;
    width: 200px;
    margin-bottom: 5px;
}
.datepicker-select {
    border-radius: 5px;
    border-collapse: collapse;
    border: #ccc 1px solid;
    padding: 5px;
    font-size: 16px;
}
.datepicker-calendar {
    font-size: 10px;
    font-weight: normal;
}
.ret-input { width: 30px;border-radius: 3px; margin-right: 5px; text-align: center;border:#ccc 1px solid}
.ret-checkbox { margin-right: 5px; }
.ret-base { color: grey; }

/******************************************************************************/

.app-pos-input {border-radius:20px}

.app-pos-container {
    background: rgb(132,227,255);
    background: -moz-linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
    background: linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#84e3ff",endColorstr="#e0ffd5",GradientType=1);
}
#app-pos {
    height: calc(100vh - 70px);
    margin: 10px;
    border: #efefef 1px solid;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.8);
}
#app-pos .customer {
    height: 100%;
    width: 419px;
    border-right: #84e3ff 1px solid;
    float: left;
    padding: 10px;
}

#app-pos .avatar { text-align: center;margin-top:12px;margin-bottom:5px}
#app-pos .avatar img { width:60px;height:60px }
#app-pos .membership { padding: 10px 0px 10px 0px }

#app-pos .membership h1 {
    font-weight: normal;
    font-size: 22px;
    color: #000;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

#app-pos .membership-info {
    background-color: #b0ebff;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
}

#app-pos .membership .fa-certificate { color: #cc5757 }
#app-pos .membership .fa-trophy { color: #e2a61b }

#mlm-ref .membership .fa-certificate { color: #cc5757 }
#mlm-ref .membership .fa-trophy { color: #e2a61b }
#mlm-ref .avatar { text-align:left;margin-top:0px}
#mlm-ref .avatar img { width:90px;height:90px }

#app-pos .deco table {
    border-collapse: collapse;
    width: 100%;
}
#app-pos .deco th {
    padding: 5px;
    border-bottom: #84e3ff 2px solid;
}

#app-pos .items {
    border: #efefef 0px solid;
    border-bottom: #efefef 1px dashed;
    height: calc(100vh - 440px);
    overflow-y: scroll;
}
#app-pos .items table { border-collapse: collapse; width: 100%;}
#app-pos .items td, th { font-size: 14px; }
#app-pos .items td { border-bottom: #ccc 1px solid; padding:5px; }
#app-pos .item-w { width: 200px; }
#app-pos .qty-w {width: 20px;text-align: left;vertical-align: top;}
#app-pos .qtyr-w {width: 20px;text-align: left;vertical-align: top;}
#app-pos .disc-w {width: 20px;text-align: center;vertical-align: top}
#app-pos .total-w {width: 70px;text-align: right;vertical-align: top}

#app-pos-modal .receipt { padding: 10px; font-size: 12px;}
#app-pos-modal .receipt table { border-collapse: collapse; width: 100%; }
#app-pos-modal .receipt td, th { font-size: 12px; }
#app-pos-modal .receipt span { font-size: 12px; }
#app-pos-modal .item-w { width: 200px; }
#app-pos-modal .qty-w {width: 20px;text-align: center;vertical-align: top;}
#app-pos-modal .qtyr-w {width: 60px;text-align: left;vertical-align: top;}
#app-pos-modal .disc-w {width: 20px;text-align: center;vertical-align: top}
#app-pos-modal .total-w {width: 50px;text-align: right;vertical-align: top}
#app-pos-modal .item-n {vertical-align:top;padding-bottom:10px}
#app-pos-modal .pay-mode-head {padding: 5px 0px 5px 0px}
#app-pos-modal .pay-mode-tail {text-align: right;vertical-align: top}
#app-pos-modal label {font-size: 20;font-weight: bold}

#app-pos .grandtotal {
    position: absolute;
    bottom: 120px;
    width: 399px;
    border-top: #84e3ff 1px solid;
    padding-top: 10px;
}
#app-pos .grandtotal h2 {
    font-size: 32px;
    text-align: right;
    margin: 0px;
    padding: 0px;
}

/******************************************************************************/

#app-pos .products {
    height: 100%;
    width: calc(100vw - 480px);
    float: left;
}
#app-pos .product-div {
    margin: 10px;
}

/******************************************************************************/

#app-pos .operation {
    height: 100%;
    padding: 0px;
    float: left;
    width: 330px;
    border-left: #84e3ff 1px solid;
}

#calculator {
    position: absolute;
    bottom: 150px;
    width: 290px;
    margin: 0px;
}

#calculator table {
    border-radius: 20px;
}

#calculator td {
    width: 50px;
    text-align: center;
    vertical-align: middle;
    height: 50px;
    background-color: #fff
}

#app-pos .sale {
    margin: 0px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 320px;
}

#app-pos .paybutton {
    position: absolute;
    bottom: 20px;
    width: 400px;
}
#payNow {
    font-size: 24px;
    text-transform: uppercase;
    border-radius:5px;
}
#noSale {
    text-transform: uppercase;
    border-radius:5px;
    font-size: 12px;
}

/******************************************************************************/

#reducer {
    margin-top: 20px;
    margin-bottom: 10px;
}
#reducer button {
    width:64px;
    height:64px;
    font-size: 32px;
    font-weight: bold;
}
#reducer .reducer-input {
    width: 64px;
    height: 64px;
    margin: 0px 5px 0px 5px;
    font-size: 32px;
    text-align: center;
    top: 1px;
    position: relative;
}
#reducer .new-discount-input {
    width: 150px;
    height: 64px;
    margin: 0px 5px 0px 5px;
    font-size: 32px;
    text-align: center;
    top: 1px;
    position: relative;
}
#reducer .refresh {
    margin-right: 0px;
}
#reducer .type {
    width: 100px;
    text-align: center;
    font-size: 32px;
}

/******************************************************************************/

#stock {
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
    border: #ccc 0px solid;
    padding: 5px;
    position: absolute;
    bottom: 15px;
    color: #fff;
    background-color: #5bc0de
}

#placeholder {
	font-size: 13px;
	border-radius: 5px;
	border: #ccc 0px solid;
	color: grey;
	padding-bottom: 10px;
	background-color: 'none'
}

/******************************************************************************/

.paynow-modal {
    width: 95%;
}

.report-modal {
    width: 75%;
}

#multipaymentwindow {
    width: 100%;
}
#multipaymentwindow small {
    color: gray
}
#multipaymentwindow label {
    text-transform: capitalize;
}
#multipaymentwindow .fa {
    margin-right: 5px;
}
#multipaymentwindow .mpw-input {
    margin-bottom: 10px;
}

/******************************************************************************/

#paymentMode {

}
#paymentMode .row {
    margin-bottom: 10px;
}
#paymentMode .btn {
    width: 150px;
}
#paymentMode h3 {
    margin:0px;
    padding:0px;
    line-height: 200%;
}

/******************************************************************************/

#popular {
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
}
#popular img {
    width: 100%;
    height: auto;
}
#popular h4 {
    margin: 0px;
    padding: 0px;
}
#popular .popular-body {
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#popular .popular-heading {
    font-weight: bold;
}
#popular .popular-info {
    margin-top: 10px;
}
